$code-color: #333333;
// $rqb-spacing: 0.5rem;
$rqb-branch-indent: 1rem;
// $rqb-border-color: red;

// @import "bootstrap/scss/bootstrap.scss";
@import "react-querybuilder/dist/query-builder.scss";

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.queryBuilder {
  .form-control,
  .form-select {
    display: inline-block;
    width: auto;
  }
  .form-select-sm,
  .form-control-sm {
    padding: 5px 14px;
  }
  .text-danger {
    color: #a94442;
  }
  .ruleGroup-addGroup + button.ruleGroup-cloneGroup,
  .ruleGroup-addGroup + button.ruleGroup-lock,
  .ruleGroup-addGroup + button.ruleGroup-remove,
  .rule-operators + button.rule-cloneRule,
  .rule-operators + button.rule-lock,
  .rule-operators + button.rule-remove,
  .rule-value + button.rule-cloneRule,
  .rule-value + button.rule-lock,
  .rule-value + button.rule-remove,
  .control + button.rule-cloneRule,
  .control + button.rule-lock,
  .control + button.rule-remove,
  .chakra-select__wrapper + button.rule-cloneRule,
  .chakra-select__wrapper + button.rule-lock,
  .chakra-select__wrapper + button.rule-remove {
    margin-left: auto;
  }
  .ruleGroup .rule,
  .ruleGroup-header {
    flex-wrap: wrap;
  }
  input[type="date"].form-control.form-control-sm {
    line-height: 23px;
  }
  .ruleGroup-header > button.ruleGroup-addRule,
  .ruleGroup-header > button.ruleGroup-addGroup {
    padding: 5px 14px;
  }
  select.rule-fields option:disabled {
    color: lightgrey;
  }
}
