/* Change app-bar to green on non-prod environments */
/* #ENV\:LOCAL ~ div header > div.app-bar, */
#ENV\:AZURE_DEV ~ div header > div.app-bar,
#ENV\:AZURE_ALTDEV ~ div header > div.app-bar,
#ENV\:AZURE_TEST ~ div header > div.app-bar,
#ENV\:AZURE_MIGRATION ~ div header > div.app-bar,
#ENV\:AZURE_CLIENT ~ div header > div.app-bar {
  background: #3e7a56;
}

#sardmtsroot {
  /* display: flex; */
  /* justify-content: center; */
  background-color: #f8f8f8;
  /* width: 100%; */
}

#def-appTop > header {
  background-color: white;
}

.sardmtsApp {
  display: flex;
  flex-direction: column;
  width: 100%;
}

/* OVERRIDES */

/* react-quill */
.ql-toolbar {
  border-top-left-radius: 4px;
  border-top-right-radius: 4px;
}
.ql-container {
  border-bottom-left-radius: 4px;
  border-bottom-right-radius: 4px;
}
.ql-container.ql-bubble > .ql-editor {
  padding: 3px 0 0 0 !important;
}

.ql-container.ql-snow > .ql-editor:focus {
  border-color: #66afe9;
  outline: 0;
  -webkit-box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075),
    0 0 8px rgba(102, 175, 233, 0.6);
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075),
    0 0 8px rgba(102, 175, 233, 0.6);
}

/* FROM VUE 2 FRONTEND */

/*.control {*/
/*  display: block;*/
/*  position: relative;*/
/*  padding-left: 28px;*/
/*  margin-bottom: 5px;*/
/*  padding-top: 0px;*/
/*  cursor: pointer;*/
/*}*/
/*.control input {*/
/*  position: absolute;*/
/*  z-index: -1;*/
/*  opacity: 0;*/
/*}*/
/*.control_indicator {*/
/*  position: absolute;*/
/*  top: 5px;*/
/*  left: 0;*/
/*  height: 20px;*/
/*  width: 20px;*/
/*  background: #ffffff;*/
/*  border: 1px solid #cccccc;*/
/*}*/
/*.control:hover input ~ .control_indicator,*/
/*.control input:focus ~ .control_indicator {*/
/*  background: #ffffff;*/
/*}*/

/*.control input:checked ~ .control_indicator {*/
/*  background: #ffffff;*/
/*}*/
/*.control:hover input:not([disabled]):checked ~ .control_indicator,*/
/*.control input:checked:focus ~ .control_indicator {*/
/*  background: #f1f1f1;*/
/*}*/
/*.control input:disabled ~ .control_indicator {*/
/*  background: #e6e6e6;*/
/*  opacity: 0.6;*/
/*  pointer-events: none;*/
/*}*/
/*.control_indicator:after {*/
/*  box-sizing: unset;*/
/*  content: '';*/
/*  position: absolute;*/
/*  display: none;*/
/*}*/
/*.control input:checked ~ .control_indicator:after {*/
/*  display: block;*/
/*}*/
/*.control-radio .control_indicator {*/
/*  border-radius: 50%;*/
/*}*/

/*.control-radio .control_indicator:after {*/
/*  left: 3px;*/
/*  top: 3px;*/
/*  height: 12px;*/
/*  width: 12px;*/
/*  border-radius: 50%;*/
/*  background: #295376;*/
/*  transition: background 250ms;*/
/*}*/
/*.control-radio input:disabled ~ .control_indicator:after {*/
/*  background: #7b7b7b;*/
/*}*/

#ribbon {
  /* min-height: 130px; */
  background: white;
  width: 100%;
  box-shadow: 1px 1px 4px 1px rgba(0, 0, 0, 0.1);
  justify-content: center;
  position: -webkit-sticky;
  position: sticky;
  top: 0;
  z-index: 99;
  word-break: break-word;
}

#ribbon .thumbnail {
  width: 80px;
  height: 80px;
  border-radius: 6px;
  border: 4px solid #ffffff;
  box-sizing: border-box;
  box-shadow: 1px 1px 4px 1px rgba(0, 0, 0, 0.1);
  margin-right: 15px;
  margin-bottom: 15px;
  padding: 0;
  -webkit-transition: all 0.2s ease-in-out;
  transition: all 0.2s ease-in-out;
}

#ribbon h2 {
  margin: 0 0 3px 0;
  font-size: 24px !important;
}

#ribbon .population {
  margin: 0 0 2px 0;
  font-size: 16px;
}

#ribbon .range {
  font-size: 14px;
}

#ribbon .species-field dl .species-data {
  /* text-align: right; */
}

#ribbon .species-field dl .species-data dd {
  margin-bottom: 2px !important;
}

@media screen and (max-width: 767px) {
  #ribbon {
    /* word-break: break-word; */
  }
  #ribbon .thumbnail {
    width: 80px;
    height: 80px;
  }
  #ribbon .flex-row2col {
    display: flex;
    flex-direction: column;
    width: 100%;
  }
  #ribbon .flex-row2col .flex-col {
    width: 100%;
  }
}

@media screen and (min-width: 768px) {
  #ribbon .flex-row2col {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    width: 100%;
  }
  #ribbon .flex-row2col > div:nth-child(1) {
    width: 45%;
  }
  #ribbon .flex-row2col > div:nth-child(2) {
    width: 55%;
  }
}

.Card {
  background: #ffffff;
  box-shadow: 1px 1px 4px 1px rgba(0, 0, 0, 0.1);
  border-radius: 6px;
  padding: 15px;
  margin-bottom: 15px;
}

.text-break {
  overflow-wrap: break-word;
}

h1 {
  font-size: 32px !important;
  border-bottom: 0 !important;
}

h1#wb-cont {
  border-bottom: 1px solid #af3c43 !important;
}

h2 {
  font-size: 20px !important;
  font-weight: 800 !important;
}

h3 {
  font-size: 18px !important;
}

h4 {
  font-size: 18px !important;
}

h5 {
  font-size: 18px !important;
}

@media (min-width: 768px) {
  .width-sm-20 {
    width: 20%;
  }

  .pagedetails > .row {
    width: 750px;
  }

  .modal-width-50 {
    width: 50% !important;
  }

  .modal-width-70 {
    width: 70% !important;
  }
}

.width-50 {
  width: 50% !important;
}

.width-100 {
  width: 100% !important;
}

.width-230 {
  width: 230px !important;
}

.width-auto {
  width: auto !important;
}

.max-width-100 {
  max-width: 100% !important;
}

.min-width-fit-content {
  min-width: -webkit-fill-available !important;
  min-width: -moz-fit-content !important;
  min-width: fit-content !important;
}

.min-width-fit-available {
  min-width: -webkit-fill-available !important;
}

.min-height-fit-content {
  min-height: -webkit-fill-available !important;
  min-height: -moz-fit-content !important;
  min-height: fit-content !important;
}

.height-fit-content {
  height: -webkit-fill-available !important;
  height: -moz-fit-content !important;
  height: fit-content !important;
}

.width-fit-content {
  width: -webkit-fill-available !important;
  width: -moz-fit-content !important;
  width: fit-content !important;
}

.flex {
  display: flex;
}
.inline-flex {
  display: inline-flex !important;
}
.inline-flex-col {
  display: inline-flex;
  flex-direction: column;
}
.flex-auto {
  flex: auto;
}
.flex-col {
  display: flex;
  flex-direction: column;
}

.flex-noshrink {
  flex-shrink: 0;
}
.flex-grow {
  flex-grow: 1;
}
.flex-wrap {
  flex-wrap: wrap;
}
.flex-1 {
  flex: 1;
}
.flex-1-1-auto {
  flex: 1 1 auto;
}

.justify-center {
  justify-content: center;
}
.justify-right {
  justify-content: right;
}
.justify-between {
  justify-content: space-between;
}
.justify-end {
  justify-content: flex-end;
}
.align-start {
  align-items: flex-start;
}
.align-center {
  align-items: center;
}
.align-end {
  align-items: flex-end;
}
.align-self-end {
  align-self: flex-end;
}
.content-center {
  align-content: center;
}

.debug1 {
  border: 1px dashed #f0f;
}
.debug2 {
  border: 1px dashed #0f0;
}

/* From Vue 2 frontend project */
main label {
  font-size: 16px;
}

.outerWrap {
  background: #f9f9f9;
  padding: 35px 0;
}

.pageWrapper {
  background: #f9f9f9;
}

.content {
  background: white;
  border-radius: 6px;
  /* box-shadow: 1px 1px 2px 2px rgba(178, 178, 178, 0.26); */
  box-shadow: 0 3.2px 7.2px 0 rgb(0 0 0 / 13%), 0 0.6px 1.8px 0 rgb(0 0 0 / 11%);
  padding: 20px;
  margin-bottom: 15px;
}

.Card .separator-line-top,
.Card .separator-line,
.Card .separator-line + :not(.separator-line) {
  margin: 0px -15px;
  padding: 15px 15px 0px 15px;
  border-top: 1px solid;
  border-top-color: #e0e0e0;
}

.Card .separator-line-top.mrgn-tp-md {
  margin-top: 15px;
}

.Card.expand-collapse {
  padding: 0;
}

.Card.expand-collapse > details {
  margin-bottom: 0;
  border: 0;
}

.Card.expand-collapse > details > summary {
  padding-top: 12px;
  padding-bottom: 12px;
  color: #333;
}

.Card.expand-collapse > details:not([open]) > summary {
  border: 0px;
}

/* .Card.expand-collapse > details[open] > summary {
  margin-bottom: 1em !important;
} */

/* Note: summary tag doesn't work as flex container.
https://github.com/philipwalton/flexbugs#flexbug-9 */
.Card.expand-collapse > details > summary > div.flexbug-9-workaround,
.Card details.expand-collapse-list > summary > div.flexbug-9-workaround {
  display: inline-block;
  width: calc(100% - 26px);
}

.Card.expand-collapse > details .list-item {
  margin: 0 -22px;
}

.Card header h2 {
  margin-top: 0px;
  padding-bottom: 5px;
}

/* The card-header-button-link class can be applied to link inside Card's header (or list item). */
/* Example, in the AddLink, EditLink, and other links */
.Card header a.card-header-button-link,
.Card .list-item a.card-header-button-link {
  font-family: lato, sans-serif;
  font-size: 16px;
  line-height: 1.4375;
  padding: 2px 5px;
  text-align: center;
}

/* The card-header-button-link class can be applied to button inside Card's header (or list item). */
/* Example, in the ExportButton, DeleteButtonModal, RefreshButton, and other buttons */
.Card header button.card-header-button-link,
.Card .list-item button.card-header-button-link {
  font-size: 16px;
  line-height: 1.4375;
  min-height: -moz-fit-content;
  min-height: fit-content;
  padding: 1px 5px !important;
}

/* The card-header-button-link class can be applied to link inside Card's collapsible header. */
/* Example, in the AddLink, EditLink, and other links */
.Card summary a.card-header-button-link {
  font-family: lato, sans-serif;
  font-size: 16px;
  line-height: 1.4375;
  padding: 2px 5px;
  text-align: center;
}

/* The card-header-button-link class can be applied to button inside Card's collapsible header. */
.Card summary button.card-header-button-link {
  font-size: 16px;
  line-height: 1.4375;
  padding: 1px 5px;
  min-height: auto;
}

.Card header .btn-group button.card-header-button-link {
  padding: 1px 15px !important;
}

details[open] > div {
  animation-name: fadeIn;
  animation-duration: 500ms;
}

@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

/* Used in the search pages. Set header background color to blue when expanded */
details.open-blue[open] > summary {
  color: #fff;
  background-color: #2572b4;
}

.btn-danger {
  color: white !important;
}

/* Make header's app-bar wide enough to fix the long application name */
.app-bar > .container > .row > .col-sm-7 {
  width: 100%;
}

/* Breadcrumb */
#wb-bc {
  background: #f9f9f9;
}

/* Section menu */
#wb-sec section > h3 {
  background-color: white;
}

/* Top nav. menu item */
#wb-sm .sm.open .slflnk a {
  background: #c9cfd4 !important;
}

/* Side nav. - Sticky */
#wb-sec {
  position: -webkit-sticky;
  position: sticky;
  top: 110px; /* Height of the Ribbon */
  z-index: 99;
}

#dataset_filter td:last-child,
#dataset_filter th:last-child {
  border-right: 0px;
}

#dataset_filter td,
#dataset_filter th {
  /* border-right: 1px solid #e0e0e0; */
  padding: 13px 10px !important;
}

#dataset_filter th {
  background: white;
  border-bottom: none !important;
  /* font-weight: normal; */
  font-size: 14px;
}

.scrollbar-x {
  display: block;
  overflow-x: auto;
  margin-bottom: 23px;
}

.scrollbar-x > table.species-table {
  margin-bottom: 0px;
}

table.species-table {
  background-color: #fff;
}

table.species-table th {
  font-size: 14px !important;
}

table.species-table td {
  font-size: 16px !important;
}

.pagedetails > .row {
  margin-right: auto;
  margin-left: auto;
}

@media screen and (min-width: 991px) and (max-width: 1200px) {
  table.species-table > thead > tr > th {
    font-size: 12.5px !important;
    padding-left: 7px;
    padding-right: 7px;
  }

  table.species-table > tbody > tr > td {
    font-size: 14px !important;
    /* word-break: break-word; */
  }
}

@media screen and (min-width: 991px) {
  table.species-table > thead > tr > th,
  table.species-table > tbody > tr > td {
    border: 1px solid #ddd !important;
  }

  table.species-table > tbody > tr > td {
    /* word-break: break-word; */
  }

  .pagedetails > .row {
    width: 970px;
  }
}

@media screen and (min-width: 1200px) {
  .pagedetails > .row {
    width: 1170px;
  }
}

@keyframes spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.spin {
  animation-name: spin;
  animation-duration: 5000ms;
  animation-iteration-count: infinite;
  animation-timing-function: linear;
}

input[type="radio"] {
  width: 20px;
  height: 20px;
  margin-top: 1px !important;
}

input[type="checkbox"] {
  width: 20px;
  height: 16px;
}

.circle-letter {
  width: 33px;
  height: 33px;
  border-radius: 250px;
  font-size: 20px;
  font-weight: normal;
  color: #000;
  line-height: 30px;
  text-align: center;
  border-style: solid;
  border-color: grey;
  border-width: 1px;
  padding: 0px 0px 0px 1px;
}

.tag-container {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
}

.tag-container > .tag-group {
  flex: 1;
  padding-bottom: 5px;
}

.tag-container > .tag-group > .tag-item:hover {
  background-color: #f0f0f0;
}

.tag-container > .tag-group > .tag-item > button {
  display: flex;
  align-items: center;
  border: 0;
  background-color: transparent;
  max-width: 100%;
}

.tag-container > .tag-reset > button {
  display: flex;
  align-items: center;
  height: -webkit-fill-available !important;
  height: -moz-fit-content !important;
  height: fit-content !important;
}

.tag-group {
  display: flex;
  flex-wrap: wrap;
  margin-bottom: -5px;
  max-width: 100%;
}

.tag-item {
  border-radius: 4px;
  border: 1px solid #d8dad3;
  font-size: 14px;
  line-height: 20px;
  padding: 4px 7px;
  white-space: nowrap;
  background-color: white;
  margin-right: 5px;
  margin-bottom: 5px;
  max-width: 100%;
}

/* Padding - extend from the theme.min.css */
/* Control the padding on an element using these rules: p{t|r|b|l}-{size} and p{x|y}-{size}*/
.p-0 {
  padding: 0 !important;
}
.pl-0,
.px-0 {
  padding-left: 0 !important;
}
.pr-0,
.px-0 {
  padding-right: 0 !important;
}
.pl-2,
.px-2 {
  padding-left: 5px !important;
}
.pr-2,
.px-2 {
  padding-right: 5px !important;
}
.pl-3,
.px-3 {
  padding-left: 15px !important;
}
.pr-3,
.px-3 {
  padding-right: 15px !important;
}
.pt-0,
.py-0 {
  padding-top: 0 !important;
}
.pb-0,
.py-0 {
  padding-bottom: 0 !important;
}
.pt-2,
.py-2 {
  padding-top: 5px !important;
}
.pb-2,
.py-2 {
  padding-bottom: 5px !important;
}
.pt-2_5,
.py-2_5 {
  padding-top: 10px !important;
}
.pb-2_5,
.py-2_5 {
  padding-bottom: 10px !important;
}
.pl-2_5,
.px-2_5 {
  padding-left: 10px !important;
}
.pr-2_5,
.px-2_5 {
  padding-right: 10px !important;
}
.pt-3,
.py-3 {
  padding-top: 15px !important;
}
.pb-3,
.py-3 {
  padding-bottom: 15px !important;
}
.pt-4,
.py-4 {
  padding-top: 30px !important;
}
.pb-4,
.py-4 {
  padding-bottom: 30px !important;
}

/* For defining font size/weight of a data field (title and value) */
.species-field dl {
  margin-bottom: 0;
  word-break: break-word;
}

.species-field dl dt {
  font-weight: 400;
  font-size: 14px;
}

.species-field dl dd {
  font-weight: 400;
  font-size: 18px;
  margin-bottom: 20px;
}

.species-field.list-item dl dd {
  font-weight: 400;
  font-size: 16px;
  margin-bottom: 20px;
}

.species-field dl.dl-inline dt {
  display: inline;
  font-weight: 700;
  font-size: 16px;
}

.species-field dl.dl-inline dd {
  display: inline-block;
  font-weight: 400;
  font-size: 16px;
  margin-bottom: 5px;
}

.checkbox-columns-lg {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-template-rows: 1fr;
  gap: 2px 10px;
  grid-auto-flow: row;
  grid-template-areas: ". . .";
  clear: both;
  padding-top: 5px;
}

.checkbox-columns-lg > .checkbox:first-child {
  margin-top: -5px;
}

.checkbox-columns-lg > .checkbox > label {
  padding-left: 23px;
}

.checkbox-columns-lg > .checkbox > label > input {
  left: 20px;
}

.gap-sm {
  gap: 5px;
}
.gap-md {
  gap: 15px;
}
.gap-lg {
  gap: 30px;
}
.gap-xl {
  gap: 50px;
}

@media screen and (max-width: 767px) {
  .species-field dl .species-data {
    display: -webkit-box !important;
    display: -ms-flexbox !important;
    display: flex !important;
    -webkit-box-align: baseline;
    -ms-flex-align: baseline;
    align-items: baseline;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
  }

  .species-field dl .species-data {
    text-align: right;
  }

  .species-field dl .species-data dt {
    text-align: left;
  }

  .species-field dl .species-data dd {
    margin-bottom: 20px;
  }

  .checkbox-columns-lg {
    grid-template-columns: 1fr 1fr;
    grid-template-areas: ". .";
  }

  .flex-wrap-sm {
    flex-wrap: wrap;
  }
}

@media screen and (max-width: 570px) {
  .checkbox-columns-lg {
    grid-template-columns: 1fr;
    grid-template-areas: ".";
  }
}

/* For grouping two date-pickers. Ex. Start Date and End Date.  */
fieldset.daterange-grp {
  border-top: 0;
  padding-top: 0;
}

fieldset.daterange-grp legend {
  font-size: 16px;
  font-weight: 700;
  margin-bottom: 5px;
}

fieldset.fieldset-grp {
  border-top: 0;
  padding-top: 0;
}

fieldset.fieldset-grp legend {
  font-size: 16px;
  font-weight: 700;
  margin-bottom: 5px;
}

fieldset.subheader-grp {
  border-top: 0;
  padding-top: 0;
}

fieldset.subheader-grp legend {
  /* font-family: Lato, sans-serif; */
  font-size: 16px;
  font-weight: 700;
  margin-bottom: 5px;
  border-bottom: 1px solid #eee;
  line-height: 23px;
}

/* Remove indentation from second row on */
.checkbox-inline + .checkbox-inline,
.radio-inline + .radio-inline {
  margin-left: 0 !important;
}

.checkbox-inline,
.radio-inline {
  margin-right: 20px;
}

.white-space-pre {
  white-space: pre;
}

.font-style-italic {
  font-style: italic;
}

.font-size-12 {
  font-size: 12px !important;
  line-height: 14px !important;
}

.font-size-14 {
  font-size: 14px !important;
  /* line-height: 1.5 !important; */
  line-height: 1.4375 !important;
}

.font-size-16 {
  font-size: 16px !important;
  line-height: 1.4375 !important;
}

.font-size-18 {
  font-size: 18px !important;
  line-height: 1.3333333 !important;
}

.font-size-20 {
  font-size: 20px !important;
}

.font-size-22 {
  font-size: 22px !important;
}

.font-size-24 {
  font-size: 24px !important;
}

.font-size-32 {
  font-size: 32px !important;
}

.font-weight-bold {
  font-weight: bold !important;
}

/* List item */
.list-item:hover {
  background-color: #f0f0f0;
}

.list-item-thumbnail {
  width: 100px;
  height: 100px;
  border-radius: 6px;
  border: 4px solid #ffffff;
  box-sizing: border-box;
  box-shadow: 1px 1px 4px 1px rgba(0, 0, 0, 0.1);
  -webkit-transition: all 0.2s ease-in-out;
  transition: all 0.2s ease-in-out;
}

.list-item-icon {
  width: 18px;
}

.list-item-title {
  font-size: 16px;
  font-weight: bold;
  line-height: 23px;
}

.list-item-description {
  font-size: 14px;
  font-weight: normal;
  line-height: 14px;
}

.list-row {
  margin: 0;
  padding: 5px;
}

/* Used in the header of a list item */
a.hover-underline {
  text-decoration: none !important;
}

a:hover.hover-underline {
  text-decoration: underline !important;
}

button:hover.hover-underline-danger {
  color: #d3080c !important;
}

button:hover.hover-grey {
  background-color: #dcdcdc !important;
  border-radius: 4px;
}

a:hover.hover-grey {
  background-color: #dcdcdc !important;
  border-radius: 4px;
}

.pointer {
  cursor: pointer;
}

/* Semicolon-separated spans */
.semicolon:not(:empty) p {
  display: inline-block;
}
.semicolon:not(:empty) ~ .semicolon:not(:empty):before {
  content: "; ";
}

.color-danger {
  color: #d3080c;
}

.relative {
  position: relative;
}

.mrgn-0 {
  margin: 0 !important;
}

.multi-select-list {
  list-style-type: none;
  margin-top: 10px;
  padding: 0px;
}

.multi-select-list-item {
  border: 1px solid #d8dad3;
  border-radius: 6px;
  padding: 5px 10px;
  margin-bottom: 5px;
  background-color: #f2f2f2;
}

pre.pre-with-line-breaks {
  overflow-x: auto;
  white-space: -moz-pre-wrap;
  white-space: -pre-wrap;
  white-space: -o-pre-wrap;
  white-space: pre-wrap;
  word-wrap: break-word;
}

.outline-width-0 {
  outline-style: none !important;
  outline-width: 0px !important;
}

details.species-details {
  padding-left: 0;
  padding-right: 0;
  /* padding-bottom: 5px; */
  margin-bottom: 0;
  border: 0 !important;
}

details.species-details > summary {
  border: 0;
  outline-width: 0;
  margin-left: -15px;
  margin-right: -15px;
  font-size: 18px;
  text-decoration: none !important;
}

details.species-details[open] > summary {
  border: 0;
  background-color: #f5f5f5;
}

details.species-details > summary:hover {
  background-color: #f0f0f0;
}

details.species-details[open] > summary.bg-grey {
  background-color: #f5f5f5;
}

details.species-details > summary.bg-grey:hover {
  background-color: #f0f0f0;
}

details.species-details > summary > .badge {
  float: right;
  margin-top: 8px;
  margin-left: 5px;
}

details.species-details > section > details.species-details > summary > .badge {
  float: right;
  margin-top: 3px;
  margin-left: 5px;
}

details.species-details > summary > .info {
  float: right;
}

details.species-details > summary::after {
  clear: both;
  display: table;
  content: " ";
}

details.species-details > section > details.species-details > summary::marker {
  content: "\25B7  ";
  font-size: smaller;
  font-weight: bold;
}

details.species-details
  > section
  > details.species-details[open]
  > summary::marker {
  content: "\25BD  ";
  font-size: smaller;
  font-weight: bold;
}

details.species-details .checkbox .badge {
  font-weight: 400;
  color: #333;
  background-color: #fff;
  border: 1px solid #d8dad3;
  height: fit-content;
  min-width: -moz-fit-content;
  min-width: -webkit-fill-available;
  min-width: fit-content;
}

details.species-details .checkbox label {
  word-break: break-word;
}

details.species-details > .checkbox::after {
  clear: both;
  display: table;
  content: " ";
}

#search-filters
  details.species-details
  > section
  > details.species-details
  > summary {
  font-size: 16px !important;
  padding: 5px 15px;
  line-height: 22px;
}

#search-filters label {
  font-size: 14px !important;
  line-height: 20px !important;
}

#search-filters .badge {
  font-size: 12px !important;
}

#search-filters input[type="checkbox"] {
  width: 13px;
  height: 13px;
}

#search-filters .autocomplete-popover {
  font-size: 14px;
  line-height: 18px;
}

.text-overflow-ellipsis {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.bg-transparent {
  background-color: transparent;
}

.bg-white {
  background-color: white;
}

.mrgn-tp-negative-md {
  margin-top: -15px;
}

.mrgn-tp-auto {
  margin-top: auto;
}

.mrgn-tp-20 {
  margin-top: 20px !important;
}

.mrgn-bttm-0 {
  margin-bottom: 0px !important;
}

.mrgn-bttm-3 {
  margin-bottom: 3px !important;
}

.mrgn-bttm-10 {
  margin-bottom: 10px !important;
}

.mrgn-lft-10 {
  margin-left: 10px !important;
}

main > div.alert:first-child,
main > section.alert:first-child {
  margin-top: 32px;
}

.alert > h3 {
  font-size: 24px !important;
}

.alert .Card.expand-collapse details {
  margin-left: 0px;
  padding-top: 0px;
}

.input-group {
  display: flex !important;
}

.input-group .search-box {
  padding-right: 37px;
  border-radius: 4px !important;
}

.input-group .search-box-reset {
  position: absolute;
  right: 0px;
  z-index: 10;
  padding: 0;
  border: 0;
  background-color: transparent;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  width: 37px;
  height: 37px;
}

.no-results-title {
  font-size: 32px;
  font-weight: 700;
  font-variant-ligatures: no-common-ligatures;
  font-family: Lato, sans-serif;
  line-height: 1.1;
  margin-top: 30px;
  margin-bottom: 20px;
  text-align: center;
}

.no-results-content {
  font-size: 24px;
  font-weight: 300;
  font-family: "Noto Sans", sans-serif;
  line-height: 1.4;
  margin-top: 10px;
  margin-bottom: 10px;
  text-align: center;
}

.no-results-content > a:visited,
a:visited > .no-results-content {
  color: #284162 !important;
}

.border-bottom-none {
  border-bottom: none !important;
}

.border-style-solid {
  border-style: solid !important;
}

.border-light {
  border: 1px solid #ececec;
  border-radius: 6px;
  padding: 10px 15px 0px 15px;
  /* margin-left: -5px; */
  /* margin-right: -5px; */
  clear: both;
}

.border-light:hover {
  background-color: #f8f8f8;
}

.well-normal {
  border: 1px solid #d8dad3;
  border-radius: 6px;
  padding: 10px 10px 0px 10px;
  margin-bottom: 10px;
  background-color: #f2f2f2;
}

.well-white-15 {
  border: 1px solid #e2e2e2;
  border-radius: 6px;
  padding: 15px;
  margin-bottom: 10px;
  background-color: white;
}

.text-decoration-underline {
  text-decoration: underline !important;
}

.text-decoration-none {
  text-decoration: none !important;
}

.text-left {
  text-align: left !important;
}

.min-height-auto {
  min-height: auto !important;
}

a.nolink,
a:visited.nolink {
  color: #284162 !important;
}

.line-height-normal {
  line-height: normal !important;
}

select > option.divider {
  font-size: 1px;
  background: lightgray;
}

.dropzone {
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px;
  border-width: 2px;
  border-radius: 2px;
  border-color: #ccc;
  border-style: dashed;
  background-color: #fafafa;
  /* color: #bdbdbd; */
  font-size: 20px;
  font-weight: 300;
  font-family: "Noto Sans", sans-serif;
  outline: none;
  transition: border 0.24s ease-in-out;
}

.tabs-style-1 .tabpanels > details {
  background: white;
}

.tabs-style-2 .tabpanels > details {
  border-radius: 0 !important;
  border-width: 1px 0 0 0 !important;
  padding: 15px 2px 0 2px !important;
  background: transparent !important;
  overflow-x: visible !important;
}

button.info-button-modal {
  padding: 1px 5px;
  min-height: auto;
  min-width: auto;
  line-height: normal;
  vertical-align: text-bottom;
}

.white-space-nowrap {
  white-space: nowrap !important;
}

.species-thumbnail {
  max-width: 150px !important;
}

.species-photo-gallery figure {
  max-width: 193px !important;
}

.species-photo-gallery figure a {
  display: block;
  overflow: hidden;
  border-radius: 6px;
}

.species-photo-gallery figure img {
  -webkit-transform: scale(1);
  transform: scale(1);
  -webkit-transition: 0.3s ease-in-out;
  transition: 0.3s ease-in-out;
}

.species-photo-gallery figure:hover img {
  -webkit-transform: scale(1.05);
  transform: scale(1.05);
}

.species-photo-gallery .img-overlay-container {
  position: relative;
}

.species-photo-gallery .img-overlay {
  position: absolute;
  bottom: 0;
  background: rgba(0, 0, 0, 0.6);
  width: 100%;
  color: #269abc;
  text-shadow: 2px 2px 5px #000;
  font-size: 14px;
  font-weight: bold;
  line-height: 30px;
  text-align: center;
}

.species-photo-gallery .img-overlay:before {
  color: #269abc;
  content: "\e084";
  display: inline-block;
  font-family: "Glyphicons Halflings";
  margin-left: -1.3em;
  position: absolute;
}

.vertical-align-top {
  vertical-align: top;
}

div[data-overlay-container]:last-of-type > .mfp-bg {
  z-index: 1043;
}

.modal-dropdown-visible {
  overflow-y: visible !important;
}

.bg-aliceblue {
  background-color: aliceblue !important;
}

.bg-lightgrey {
  background-color: lightgrey !important;
}

/* Grey out Richtext editor when disabled. */
.quill div.ql-container.ql-snow.ql-disabled {
  border: 1px solid #ccc;
  border-top-left-radius: 4px;
  border-top-right-radius: 4px;
}

.quill div.ql-container.ql-snow.ql-disabled > div.ql-editor,
.quill div.ql-container.ql-snow.ql-disabled > div.ql-editor > p {
  cursor: not-allowed;
  background-color: #eee;
  opacity: 1;
}

.quill div.ql-snow.ql-toolbar:has(+ div.ql-container.ql-snow.ql-disabled) {
  display: none;
}

.quill:has(div.ql-container.ql-bubble.ql-disabled),
.quill:has(div.ql-container.ql-bubble.ql-disabled) p {
  cursor: not-allowed;
  background-color: #eee;
  opacity: 1;
}

details.detail-list {
  margin-bottom: 10px;
}

details.detail-list.bg-grey {
  background-color: #f5f5f5;
  visibility: visible !important;
}

details.detail-list.bg-grey:hover {
  background-color: #f0f0f0;
}

details.detail-list[open] > summary {
  margin-bottom: 15px;
}

@media screen and (max-width: 991px) {
  .provisional.gc-table td {
    max-width: none !important;
  }
}

.template-list {
  list-style-type: none;
  /* margin-top: 10px; */
  padding: 0px;
}

.template-list-single {
  margin-bottom: 10px;
}

.template-list-bundle {
  min-height: 20px;
  padding: 10px 10px 0px 10px;
  margin-bottom: 10px;
  /* border: 1px solid #e3e3e3; */
  border: 1px solid #d8dad3;
  border-radius: 6px;
  /* -webkit-box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.05); */
  /* box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.05); */
  border-left-width: 7px;
}

.template-list-bundle:hover {
  border-left-color: darkgrey;
}

.template-list-bundle .template-list-item {
  margin-bottom: 10px;
}

.template-list-item {
  border: 1px solid #d8dad3;
  border-radius: 6px;
  padding: 5px 10px;
  background-color: #f5f5f5;
}

.new-bundle-animation {
  animation: highlight 1000ms ease;
}
@keyframes highlight {
  0% {
    background-color: #ebf2fc;
    border-color: #0077e6;
  }
  100% {
    background-color: auto;
    border-color: auto;
  }
}

.detail-list-phase h3 {
  font-family: "Noto Sans", sans-serif;
  font-weight: 400;
  margin: 0;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  width: 60%;
}

.detail-list-step h3 {
  font-family: "Noto Sans", sans-serif;
  font-weight: 400;
  margin: 0;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  width: 60%;
}

.detail-list-activity h5 {
  font-family: "Noto Sans", sans-serif;
  font-weight: 400;
  margin: 0;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  width: 60%;
}

.detail-list-activity,
.detail-list-activity > summary,
.detail-list-activity > summary:focus {
  background-color: white;
}

.detail-list-activity > summary:hover {
  background-color: whitesmoke;
}

.detail-list-phase .status-tag,
.detail-list-step .status-tag,
.detail-list-activity .status-tag {
  border: 1px solid;
  padding: 1px 9px;
  border-radius: 11px;
  background-color: white;
  border-color: lightgrey;
  font-size: 14px;
  font-style: italic;
  line-height: 1.4375;
}

.status-tag-page {
  border: 1px solid;
  padding: 1px 9px;
  border-radius: 18px;
  background-color: white;
  border-color: lightgrey;
  font-size: 20px;
  font-style: italic;
  white-space: nowrap;
}

.hide-hyperlink a,
.hide-hyperlink a:visited,
.ql-snow .ql-editor a,
.ql-snow .ql-editor a:visited {
  text-decoration: none;
  pointer-events: none;
  cursor: default;
  color: #333;
}

.ql-snow .ql-tooltip {
  display: none;
}

.display-none {
  display: none;
}

p {
  font-size: 18px;
}
