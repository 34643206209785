.SearchListItem {
  padding: 12px;
  border-bottom: 1px solid #e0e0e0;
}

.SearchListItem a {
  text-decoration: none;
}

.SearchListItem a:hover {
  text-decoration: underline;
}

.SearchListItem h1,
.SearchListItem h2,
.SearchListItem h3,
.SearchListItem h4,
.SearchListItem h5,
.SearchListItem h6,
.SearchListItem p {
  margin: 0;
  padding: 0;
}

.Population {
  color: #666666;
  font-size: 16px;
  font-weight: normal;
}

.SearchListItem p {
  margin: 0 !important;
  padding: 0 !important;
}

.Thumbnail {
  width: 100px;
  height: 100px;
  border-radius: 6px;
  background: #c5d6de;
}
